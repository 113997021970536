<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from 'axios';
import appConfig from "@/app.config";

export default {
  page: {
    title: "Saques",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Saques",
      items: [
        {
          text: "Saques",
          active: true
        }
      ],
      cashout: []
    };
  },
  created(){
    this.user = JSON.parse(localStorage.getItem('user'));
    this.getData()
  },
  methods:{
    getData(url){
      this.loading = true
      let config = {
        method: 'get',
        url: url ? url : `${process.env.VUE_APP_API}api/v1/admin/cashout`,
        headers: { 
          'Accept': 'application/json', 
          'Authorization': `Bearer ${this.user.access_token}`
        }
      };

      axios(config)
      .then( (response) => {
        this.loading = false
        this.cashout = response.data.data
        
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card h-100">
          
          <div class="card-body">
            <div class="table-responsive mt-3">
              <b-overlay :show="loading" rounded="sm">
                <table
                  class="table table-centered datatable dt-responsive nowrap"
                  style="border-collapse: collapse; border-spacing: 0; width: 100%;"
                >
                  <thead class="thead-light">
                    <tr>
                      <th style="width: 20px;">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="customercheck" />
                          <label class="custom-control-label" for="customercheck">&nbsp;</label>
                        </div>
                      </th>
                      <th>Nome</th>
                      <th>Valor</th>
                      <th>Criado em</th>
                      <th>Status</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in cashout" :key="index">
                      <td>
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            :id="`customercheck${index}`"
                          />
                          <label class="custom-control-label" :for="`customercheck${index}`">&nbsp;</label>
                        </div>
                      </td>
                      <td>{{item.user.name}}</td>
                      <td>R$ {{item.value | price}}</td>
                      <td>{{item.created_at | date}}</td>
                      <td>{{item.pending ? "Pendente" : item.recused ? "Recusado" : "OK" }}</td>
                      <td>
                        <router-link
                          class="mr-3 text-primary"
                          v-b-tooltip.hover
                          title="Editar"
                          :to="`/cashout/${item.id}`"
                        >
                          <i class="mdi mdi-pencil font-size-18"></i>
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-overlay>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>